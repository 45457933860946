<!-- #Developer by Halil Kılıçarslan -->

<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :items="agencies"
    item-value="id"
    item-text="name"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
  >
    <template v-slot:prepend-item v-if="$attrs.multiple">
      <v-list-item ripple @click="selectAll">
        <v-list-item-action>
          <v-icon :color="selected.length > 0 ? 'primary' : ''">{{ _icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("buttons.select_all") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
    <template v-if="$attrs.multiple" v-slot:selection="{ item, index }">
      <div v-if="index === 0">{{ item.name }}</div>
      <span v-if="index === 1" class="grey--text caption ml-1"> (+{{ selected.length - 1 }}) </span>
    </template>
    <template v-else v-slot:selection="{ item, index }">
      {{ item.name }}
    </template>
    <template slot="item" slot-scope="data">
      <template>
        <v-list-item-avatar size="30" color="primary">
          <span class="white--text font-weight-bold">{{ data.item.name.substring(0, 2).toUpperCase() }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name" />
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  import * as GQL from "./gql";

  export default {
    name: "AgencyPicker",
    data: () => ({
      loading: false,
      selected: null,
      agencies: []
    }),
    props: {
      value: [String, Object, Array]
    },
    watch: {
      value: {
        handler(v) {
          if (v) this.selected = v;
        },
        immediate: true
      }
    },
    computed: {
      isAllSelected() {
        return this.selected.length === this.agencies.length;
      },
      isLikesSelected() {
        return this.selected.length > 0 && !this.isAllSelected;
      },
      _icon() {
        if (this.isAllSelected) return "mdi-close-box";
        if (this.isLikesSelected) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      }
    },
    methods: {
      selectAll() {
        this.$nextTick(() => {
          if (this.isAllSelected) {
            this.selected = [];
          } else {
            this.selected = this.agencies.map(item => item.id);
          }
          this.$emit("input", this.selected);
        });
      },
      async fetchItems() {
        this.agencies = [];
        this.loading = true;
        await this.$apollo
          .query({
            query: GQL.AGENCY,
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { agencies }, errors }) => {
            if (!errors || !data.error) {
              this.agencies = agencies;
            }
          })
          .catch(e => {
            console.log("e", e);
          })
          .then(() => (this.loading = false));
      }
    },

    created() {
      this.fetchItems();
    }
  };
</script>
