import gql from "graphql-tag";

export const AGENCY = gql`
  query agencies {
    agencies {
      id
      name
    }
  }
`;
